/**
 * @description 公共图标管理
 */

// 文件夹
export const FolderIcon = require('@/assets/icon/folder.png')
// excel文件
export const xlsIcon = require('@/assets/icon/xls.png')
// excel文件夹
export const excelFolderIcon = require('@/assets/icon/excel.png')
// delete
export const del = require('@/assets/icon/delete.png')
